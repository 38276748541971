
import { defineComponent } from "vue";
import { IonPage, IonContent, onIonViewWillEnter } from "@ionic/vue";
import settingsApp from "@/libs/settings";
import httpApp from "@/libs/http";
import { useI18n } from "vue-i18n";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import { useRouter, useRoute } from "vue-router";
import sessionApp from "@/libs/session";
import { useStore } from "@/store";

export default defineComponent({
	props: ["type", "order_no", "payment_method"],
	setup(props) {
		const { t, te } = useI18n({ useScope: "global" });
		const router = useRouter();
		const route = useRoute();
		const storeX = useStore();
		const { createLoading, openToast } = useGlobalFunctions();

		const mpgsValidatePayment = async () => {
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("mpgsValidatePayment"), {
					order_no: props.order_no,
					resultIndicator: route.query["resultIndicator"],
					sessionVersion: route.query["sessionVersion"],
				})
				.then((resp: any) => {
					loading.dismiss();
					if (resp.success) {
						openToast(t("checkout.paymentSuccessMsg"), "success");
						router.replace("/checkout/success/" + props.order_no);
					} else {
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							openToast(resp.error.message, "danger");
						}
						router.replace("/cart");
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
				});
		};

		const mpgsCancelPayment = async () => {
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("mpgsCancelPayment"), {
					order_no: props.order_no,
					error: props.type == "error" ? route.query["error"] : false,
				})
				.then((resp: any) => {
					loading.dismiss();
					if (resp.success) {
						if (props.type == "cancel") {
							openToast(t("checkout.paymentCanceled"), "danger");
							router.replace("/");
						} else {
							openToast(route.query["error"]["explanation"], "danger");
							router.replace("/cart");
						}
					} else {
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							openToast(resp.error.message, "danger");
						}
						router.replace("/");
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
				});
		};

		const stryveValidatePayment = async () => {
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			const sentData = route.query;
			sentData["order_no"] = props.order_no;
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("stryveValidatePayment"),
					sentData
				)
				.then((resp: any) => {
					loading.dismiss();
					if (resp.success) {
						openToast(t("checkout.paymentSuccessMsg"), "success");
						router.replace("/checkout/success/" + props.order_no);
					} else {
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							openToast(resp.error.message, "danger");
						}
						router.replace("/cart");
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
				});
		};

		const stryveCancelPayment = async () => {
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("stryveCancelPayment"), {
					order_no: props.order_no,
					error: props.type == "error" ? route.query["error"] : false,
				})
				.then((resp: any) => {
					loading.dismiss();
					if (resp.success) {
						if (props.type == "cancel") {
							openToast(t("checkout.paymentCanceled"), "danger");
							router.replace("/");
						} else {
							openToast(route.query["error"]["explanation"], "danger");
							router.replace("/cart");
						}
					} else {
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							openToast(resp.error.message, "danger");
						}
						router.replace("/");
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
				});
		};

		const paytabsValidatePayment = async () => {
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			const sentData = route.query;
			sentData["order_no"] = props.order_no;
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("paytabsValidatePayment"),
					sentData
				)
				.then((resp: any) => {
					loading.dismiss();
					if (resp.success) {
						openToast(t("checkout.paymentSuccessMsg"), "success");
						router.replace("/checkout/success/" + props.order_no);
					} else {
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							openToast(resp.error.message, "danger");
						}
						router.replace("/cart");
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
				});
		};

		const paytabsCancelPayment = async () => {
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("paytabsCancelPayment"), {
					order_no: props.order_no,
					error: props.type == "error" ? route.query["error"] : false,
				})
				.then((resp: any) => {
					loading.dismiss();
					if (resp.success) {
						if (props.type == "cancel") {
							openToast(t("checkout.paymentCanceled"), "danger");
							router.replace("/");
						} else {
							openToast(route.query["error"]["explanation"], "danger");
							router.replace("/cart");
						}
					} else {
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							openToast(resp.error.message, "danger");
						}
						router.replace("/");
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
				});
		};

		const fawryValidatePayment = async () => {
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			const sentData = route.query;
			sentData["order_no"] = props.order_no;
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("fawryValidatePayment"),
					sentData
				)
				.then((resp: any) => {
					loading.dismiss();
					if (resp.success) {
						if (resp.paid) {
							openToast(t("checkout.paymentSuccessMsg"), "success");
							router.replace("/checkout/success/" + props.order_no);
						} else {
							router.replace(
								"/checkout/success/" +
									props.order_no +
									"/?reference_number=" +
									resp.data.reference_number +
									"&expire_at=" +
									resp.data.expire_at
							);
						}
					} else {
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							openToast(resp.error.message, "danger");
						}
						router.replace("/cart");
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
				});
		};

		const fawryCancelPayment = async () => {
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("fawryCancelPayment"), {
					order_no: props.order_no,
					error: props.type == "error" ? route.query["error"] : false,
				})
				.then((resp: any) => {
					loading.dismiss();
					if (resp.success) {
						if (props.type == "cancel") {
							openToast(t("checkout.paymentCanceled"), "danger");
							router.replace("/");
						} else {
							openToast(route.query["error"]["explanation"], "danger");
							router.replace("/cart");
						}
					} else {
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
						} else {
							openToast(resp.error.message, "danger");
						}
						router.replace("/");
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
				});
		};

		onIonViewWillEnter(() => {
			if (props.payment_method == "egyprotech_mpgshc") {
				if (props.type == "return") {
					mpgsValidatePayment();
					return;
				} else if (props.type == "error" || props.type == "cancel") {
					mpgsCancelPayment();
					return;
				}
			} else if (props.payment_method == "egyprotech_stryve") {
				if (props.type == "return") {
					stryveValidatePayment();
					return;
				} else if (props.type == "error" || props.type == "cancel") {
					stryveCancelPayment();
					return;
				}
			} else if (props.payment_method == "egyprotech_paytabs") {
				if (props.type == "return") {
					paytabsValidatePayment();
					return;
				} else if (props.type == "error" || props.type == "cancel") {
					paytabsCancelPayment();
					return;
				}
			} else if (props.payment_method == "egyprotech_fawry") {
				if (props.type == "return") {
					fawryValidatePayment();
					return;
				} else if (props.type == "error" || props.type == "cancel") {
					fawryCancelPayment();
					return;
				}
			}
			sessionApp.getCustomerData().then((response: any) => {
				storeX.commit("setCustomerData", response);
			});
			router.replace("/cart");
		});

		return {
			t,
		};
	},
	components: {
		IonPage,
		IonContent,
	},
});
